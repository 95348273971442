
document.addEventListener('DOMContentLoaded', function() {
	var languageSelector = document.querySelector('.language-selector');
	
	languageSelector.addEventListener('click', function() {
	  this.classList.toggle('active');
	  if (document.querySelector('.user-logado') && document.querySelector('.user-logado').classList.contains('active')) document.querySelector('.user-logado').classList.toggle('active');
	  if (document.querySelector('.carrito-header').classList.contains('active')) document.querySelector('.carrito-header').classList.toggle('active');
	});
  
  
  
	 var cabeceraAux = document.querySelector('header');
	 if(!cabeceraAux.classList.contains('opaco')){
		  window.addEventListener('scroll', function() {
			var cabecera = document.querySelector('header');
			var scrollPos = window.scrollY;
  
			if (scrollPos > 0) {
			  cabecera.classList.add('opaco');
  
			} else {
			  cabecera.classList.remove('opaco');
			}
		  });
  
		  var scrollPos2 = window.scrollY;
		  if (scrollPos2 > 0) {
			  var cabecera2 = document.querySelector('header');
			  cabecera2.classList.add('opaco');
		  }
  
	  }
  });
  
  document.addEventListener('DOMContentLoaded', function() {
  // carrito
	  var carritoHeader = document.querySelector('.carrito-header');
	  
  
	  carritoHeader.addEventListener('click', function(event) {
		  var carritoElementGrey = document.querySelector('.carrito-header .shopping-cart.grey');
		  var carritoElementWhite = document.querySelector('.carrito-header .shopping-cart.white');
		  if (event.target == carritoElementGrey) this.classList.toggle('active');
		  if (event.target == carritoElementWhite) this.classList.toggle('active');
		  
		  if (document.querySelector('.language-selector').classList.contains('active')) document.querySelector('.language-selector').classList.toggle('active');
		  if (document.querySelector('.user-logado') && document.querySelector('.user-logado').classList.contains('active')) document.querySelector('.user-logado').classList.toggle('active');
	  });
  
  
  // user logado
	var userLogado = document.querySelector('.user-logado');
	if(userLogado){
		userLogado.addEventListener('click', function() {
		  this.classList.toggle('active');
		  if (document.querySelector('.language-selector').classList.contains('active')) document.querySelector('.language-selector').classList.toggle('active');
		  if (document.querySelector('.carrito-header').classList.contains('active')) document.querySelector('.carrito-header').classList.toggle('active');
		});
		
		registroModal = document.getElementById('registroModal')
		selectRegistroPais = document.getElementById('registro-pais')
		cerrarModalRegistroVar = document.getElementById('cerrarModalRegistro')
		// if (registroModal) {
		// 	registroModal.addEventListener('click', handleRegistroModalClick);
		// }
		if (cerrarModalRegistroVar) {
			cerrarModalRegistroVar.addEventListener('click', cerrarModalRegistro)
		}
		if (selectRegistroPais) {
			selectRegistroPais.addEventListener('change', handleChangePaisRegistro)
		}

	  }
  
  
  
  // user no logado
  
	var userNoLogado = document.querySelector('.login-user')
	if(userNoLogado){
		userNoLogado.addEventListener('click', redireccionLogin)	
	  }  

  	  function redireccionLogin() {
		const currentUrl = window.location.href;
		const url = new URL(currentUrl);
		const relativePath = url.pathname + url.search;
		if (currentUrl.includes(process.env.MIX_PORTAL_DOCENTE_DOMAIN)) {
			window.open(process.env.MIX_URL_LOGIN_PORTAL, "_self");
		} else {
			const urlLogin = process.env.MIX_URL_LOGIN + "&state=" + encodeURIComponent(relativePath);
			window.open(urlLogin, "_self");
		}
	  }


	  function handleChangePaisRegistro(event) {
		var elementos = document.querySelectorAll('.registro-spain');
		const inputs = document.querySelectorAll('.registro-spain-input');

		  if(event.target.value==="ES" || event.target.value===""){
		    elementos.forEach(function(elemento) {
		        elemento.classList.remove('d-none');
		    });
		    inputs.forEach(input => {
   				input.setAttribute ('required','required');
			});
		  }else{
			elementos.forEach(function(elemento) {
		        elemento.classList.add('d-none');
		    });
			inputs.forEach(input => {
   				input.removeAttribute('required');
			});		    
		  }
	  }

	var params = new URLSearchParams(window.location.search);
	var parametroAddData = params.get('addData');  

    
    if (parametroAddData !== null && parametroAddData==1) {
		abrirModalRegistro()
	}

	//   function handleRegistroModalClick(event) {
	// 	  registroModal = document.getElementById('registroModal')
	// 	  if (event.target === registroModal) {
	// 		  cerrarModalRegistro()
	// 	  }
	//   }		
  
	  function cerrarModalRegistro() {
	  	const currentUrl = window.location.href;
		var url = new URL(currentUrl)
		if (!currentUrl.includes(process.env.MIX_PORTAL_DOCENTE_DOMAIN)) {
			url.searchParams.delete('addData')
			history.replaceState(null, null, url.toString());	
			registroModal = document.getElementById('registroModal')
			registroModal.classList.remove('show')
			registroModal.style.display = 'none'
		}
	  }
  
	  function abrirModalRegistro() {
		  registroModal = document.getElementById('registroModal')
		  registroModal.classList.add('show')
		  registroModal.style.display = 'block'
	  }		

	  function handleImageError(image) {
        const fallbackUrl = image.getAttribute('data-fallback');
        image.src = fallbackUrl;
        image.onerror = null;
	  }
	  window.handleImageError = handleImageError

	  function removeSelectedSchool(){
        document.getElementById("nombreColegio").value="";
        document.getElementById("idColegio").value="";
        document.getElementById('school-search-container').classList.remove("d-none");
        document.getElementById('selectedColegioContainer').classList.add("d-none");
        document.getElementById('school-search-button').classList.add("mm-disabled");
		if(document.getElementById('cuenta-section-ayuda') != null){
			document.getElementById('cuenta-section-ayuda').classList.remove("d-none");
		}
		if(document.getElementById('cuenta-boton-guardar') != null){
			// document.getElementById('cuenta-boton-guardar').classList.add("mm-disabled");
		}
        disableRegisterSubmitButton(true)
    }
    window.removeSelectedSchool = removeSelectedSchool;
    
    function disableRegisterSubmitButton(checker){
        const button = document.getElementById('form-registro-usuario-submit');
		if(button !== null) {
			if(checker) {
				button.classList.add("mm-disabled");
			} else {
				button.classList.remove("mm-disabled");
			}
		}
    }
    window.disableRegisterSubmitButton = disableRegisterSubmitButton;

	function checkIfInputsAreEmpty() {
		
		const form = document.getElementById("form-registro-usuario")
		if(form !== null){
			const requiredTextFields = form.querySelectorAll("input[type='text'][required]");
			const requiredSelectFields = form.querySelectorAll("select[required]");
			const button = document.getElementById('form-registro-usuario-submit');
			let allFilled = true;
			
			requiredTextFields.forEach(field => {
				if (field.value === "") {
					button.classList.add("mm-disabled");
					allFilled = false
				}
			});

			requiredSelectFields.forEach(field => {
				if (field.value === "") {
					button.classList.add("mm-disabled");
					allFilled = false
				}
			});

			if(document.getElementById("nombreColegio") != null && document.getElementById("nombreColegio").value === " - "){
				button.classList.add("mm-disabled");
				allFilled = false
			}
			if(!allFilled){
				disableRegisterSubmitButton(true)
			}else{
				disableRegisterSubmitButton(false)
			}
			return allFilled;
		}
	}
	window.checkIfInputsAreEmpty = checkIfInputsAreEmpty;


	const registerForm = document.getElementById("form-registro-usuario");
    
	if(registerForm != null){
		const requiredFields = registerForm.querySelectorAll("input[type='text'][required], select[required]");
		requiredFields.forEach(field => {
			field.addEventListener("input", checkIfInputsAreEmpty);
			field.addEventListener("change", checkIfInputsAreEmpty);
		});
	}

	function setCookie(name, value, hours) {
        let date = new Date();
        date.setTime(date.getTime() + (hours * 60 * 60 * 1000));
        document.cookie = name + "=" + value + "; expires=" + date.toUTCString() + "; path=/";
    }

    function getCookie(name) {
        let nameEQ = name + "=";
        let ca = document.cookie.split(';');
        for(let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

	if(!getCookie("expireAnayaData")){
		setCookie("expireAnayaData", "true", 10);
		let getData = ['Cursos', 'Series', 'Etapas', 'Ciclos', 'Autonomias', 'Asignaturas', 'Idiomas', 'Comunidades', 'Sellos'];
		getData.forEach(element => {
			localStorage.removeItem('data' + element);
		});
	}

  });
  